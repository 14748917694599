
import { Options, Vue } from 'vue-class-component';
import { ProviderClient } from '@/services/Services';
import * as OM from '@/Model';
import CalendarDayCertificationsModal from '../modals/calendarDayCertificationsModal.vue';

@Options({
    components: {}
})
export default class CalendarCertifications extends Vue {

    today: Date = new Date();
    currentYear: number = this.today.getFullYear();
    currentMonth: number = this.today.getMonth();

    dayNames: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    dayData: OM.CertificationExpirationForCalendarVM[] = [];

    created() {
        this.init();
    }

    init() {
        ProviderClient.getAllExpirationDatesOfCertification()
        .then(x => {
            this.dayData = x;
        })
    }

    getCertificationsByDate(date: Date) {
        var formattedDate = new Date(date);
        var ris = [];

        this.dayData.forEach(element => {
            var elDate = new Date(element.expirationDate);
            if(elDate.getFullYear() == formattedDate.getFullYear() && elDate.getMonth() == formattedDate.getMonth() && elDate.getDate() == formattedDate.getDate())
                ris.push(element);
        });

        return ris;
    }

    get currentMonthYear() {
        const date = new Date(this.currentYear, this.currentMonth);
        return date.toLocaleString('default', { month: 'long', year: 'numeric' });
    }

    get calendarDays() {
        const firstDay = new Date(this.currentYear, this.currentMonth, 1);
        const daysInMonth = new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
        const startDay = firstDay.getDay(); // 0 = Sunday

        const days = [];

        // Giorni vuoti prima dell'inizio del mese
        for (let i = 0; i < startDay; i++) {
            days.push({ date: null });
        }

        // Giorni del mese
        for (let day = 1; day <= daysInMonth; day++) {
            const date = new Date(this.currentYear, this.currentMonth, day);
            const key = this.formatDateKey(date);
            days.push({ date: date, dateKey: key });
        }

        return days;
    }

    isPastDay(date) {
        if (!date) return false;

        const today = new Date();
        return date < today;
    }
    isToday(date) {
        if (!date) return false;

        const today = new Date();
        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    }

    nextMonth() {
        if (this.currentMonth === 11) {
            this.currentMonth = 0;
            this.currentYear++;
        } else {
            this.currentMonth++;
        }
    }
    prevMonth() {
        if (this.currentMonth === 0) {
            this.currentMonth = 11;
            this.currentYear--;
        } else {
            this.currentMonth--;
        }
    }

    formatDateKey(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
        // return date.toISOString().slice(0, 10); // YYYY-MM-DD
    }
    handleDayClick(date) {
        var certifications = this.getCertificationsByDate(date);
        this.$opModal.show(CalendarDayCertificationsModal, {
            selectedDate: date,
            certifications: certifications
        })
    }

}
