
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class CalendarDayCertificationsModal extends Vue {

    @Prop() selectedDate: Date;
    @Prop() certifications: any[];

    created() {}

    goToProviderCertifications(providerId: string) {
        this.$opModal.closeLast();
        this.$router.replace("/edit-provider/" + providerId + '/2')
    }

}
